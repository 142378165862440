import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { MainScreen, MemoAd } from "../../UiElements";
import "./eventLimitExceededPage.scss";

import sadFace from "./images/sadFace.png";

class EventLimitExceededPage extends Component {
  render() {
    const { t } = this.props;

    return (
      <MainScreen id="event-limit-exceeded" footerElement={<MemoAd />}>
        <div className="wrapper">
          <img className="image" src={sadFace} alt="Sad face" />
          <h1 className="title">{t("eventLimitExceededTitle")}</h1>
          <h2 className="subtitle-line-1">
            {t("eventLimitExceededSubtitleLine1")}
          </h2>
          <h2 className="subtitle-line-2">
            {t("eventLimitExceededSubtitleLine2")}
          </h2>
        </div>
      </MainScreen>
    );
  }
}

const EventLimitExceededPageWithT = withTranslation()(EventLimitExceededPage);
export { EventLimitExceededPageWithT as EventLimitExceededPage };
