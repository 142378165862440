import React, { Component } from "react";
import { Switch, Route, Router } from "react-router-dom";
import { connect } from "react-redux";
import { EventActions } from "../../actions";
import { history } from "../../utils";
import { Welcome } from "../Welcome";
import { Page404 } from "../Page404";
import { Camera } from "../Camera";
import {
  EventClosedPage,
  EventFinishedPage,
  EventLimitExceededPage,
  EventWaitingPage,
} from "../EventStatusPages";

import "./app.scss";

class App extends Component {
  componentWillMount() {
    const { dispatch, event } = this.props;

    dispatch(EventActions.getEventData());
    EventActions.pageTitle(event.title);
  }

  componentWillUpdate(nextProps) {
    if (this.props.event.title !== nextProps.event.title) {
      EventActions.pageTitle(nextProps.event.title);
    }
  }

  render() {
    return (
      <div id="main">
        <Router history={history}>
          <Switch>
            <Route exact path="/:shortenUrl" component={Welcome} />
            <Route exact path="/:shortenUrl/cam" component={Camera} />
            <Route exact path="/:shortenUrl/error-404" component={Page404} />
            <Route
              exact
              path="/:shortenUrl/event-closed"
              component={EventClosedPage}
            />
            <Route
              exact
              path="/:shortenUrl/event-finished"
              component={EventFinishedPage}
            />
            <Route
              exact
              path="/:shortenUrl/event-waiting"
              component={EventWaitingPage}
            />
            <Route
              exact
              path="/:shortenUrl/event-limit-exceeded"
              component={EventLimitExceededPage}
            />
            <Route exact path="*" component={Page404} />
          </Switch>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.eventReducer,
  };
};

const AppWithRedux = connect(mapStateToProps)(App);

export { AppWithRedux as App };
