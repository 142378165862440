import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import "./preview.scss";

class Preview extends Component {
  render() {
    return (
      <button id="camera-preview-btn" onClick={this.props.onClick}>
        {this.props.t("preview")}
      </button>
    );
  }
}

const PreviewWithT = withTranslation()(Preview);

export { PreviewWithT as Preview };
