import { uploadConstants } from "../constants";

const uploadReducer = (state = {}, action) => {
  switch (action.type) {
    case uploadConstants.UPLOAD_REQUEST:
      return {
        ...state,
        uploadInProcess: true,
        successUpload: false,
        uploadError: false,
        process: 0,
      };

    case uploadConstants.UPLOAD_SUCCESS:
      return {
        ...state,
        uploadInProcess: false,
        successUpload: true,
        uploadError: false,
        uploadResponse: action.payload,
      };

    case uploadConstants.UPLOAD_FAILURE:
      return {
        ...state,
        uploadInProcess: false,
        successUpload: false,
        uploadError: true,
      };

    case uploadConstants.SET_UPLOAD_PROCESS:
      return {
        ...state,
        process: action.payload,
      };

    case uploadConstants.RESET_UPLOAD:
      return {
        ...state,
        uploadInProcess: false,
        successUpload: false,
        uploadError: false,
        process: 0,
      };

    default:
      return state;
  }
};

export default uploadReducer;
