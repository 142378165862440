import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const { REACT_APP_DEFAULT_LANG } = process.env;

i18n.use(initReactI18next).init({
  interpolation: {
    // React already does escaping (safes from xss)
    escapeValue: false,
  },
  lng: REACT_APP_DEFAULT_LANG,
  fallbackLng: "en",
  resources: {
    en: {
      translation: {
        termsOfUse: "Terms of Use",
        eventClosed: "Event Closed",
        eventFinished: "Event Finished",
        eventFinishedSubtitle: "",
        eventWaiting: "",
        preview: "",
        copies: "Copies",
        sendingForPrintingTitle: "Uploading...",
        sendingForPrintingSubtitle: "",
        onSuccessUploadFirstLine: "",
        onSuccessUploadSecondLine: "",
        onSuccessButton: "",
        imageCounter: "",
        limitBigTitle: "",
        limitSubTitle: "",
        errorBigTitle: "",
        errorSubTitle: "",
        blockedBigTitle: "",
        blockedSubTitle: "",
        pageWaitingTitle: "",
        pageWaitingSubtitleLine1: "",
        pageWaitingSubtitleLine2: "",
        horizontalScreenWarning: "",
        wantMemoAtYourEvent: "",
        clickForMoreDetails: "",
        sendToPrint: "",
        return: "",
        eventLimitExceededTitle: "",
        eventLimitExceededSubtitleLine1: "",
        eventLimitExceededSubtitleLine2: "",
      },
    },
    he: {
      translation: {
        termsOfUse: "תנאי שימוש",
        eventClosed: "האירוע סגור",
        previewTitle: "וואו! איזה יופי!",
        previewSubtitle: "הממו שלך יראה בדיוק כך, ויחכה לך בעמדה",
        sendToPrint: "אהבתי, תדפיס לי!",
        eventFinished: "האירוע הסתיים!",
        eventFinishedSubtitle: "נפגש בפעם הבאה :)",
        eventWaiting: "האירוע עדיין לא נפתח",
        preview: "שלח להדפסה",
        return: "חזור",
        copies: "עותקים",
        sendingForPrintingTitle: "שולחים להדפסה...",
        sendingForPrintingSubtitle: "כמה רגעים :)",
        onSuccessUpload: "התמונה שלך מוכנה!",
        onSuccessUploadSecondLine: "ומחכה לך בעמדת הממו שלנו :)",
        onSuccessButton: "פצצה, בא לי עוד!",
        onSuccessButtonLimitZero: "נפגש באירוע הבא",
        imageCounter: "נשארו לך עוד {{number}} תמונות להדפיס",
        limitBigTitle: "שמחים שנהנתם!",
        limitSubTitle: "הגעתם למכסת התמונות שנקבעה",
        errorBigTitle: "אירעה שגיאה.",
        errorSubTitle: "גם זה קורה.. נסו שוב!",
        blockedBigTitle: "אופס!",
        blockedSubTitle: "לא ניתן להעלות תמונה מלפני תחילת האירוע",
        pageWaitingTitle: "האירוע עדיין לא התחיל!",
        pageWaitingSubtitleLine1: "עוד קצת סבלנות ותוכלו להתחיל ליצור זכרונות.",
        pageWaitingSubtitleLine2: "בנתיים, לכו להתייפיף :)",
        page404Title: "האירוע הסתיים!",
        page404Subtitle: "נפגש בפעם הבאה :)",
        horizontalScreenWarning: "אנא הפכו את המכשיר",
        wantMemoAtYourEvent: "רוצים את ממו באירוע שלכם גם?",
        clickForMoreDetails: "הקליקו לפרטים נוספים",
        eventLimitExceededTitle: "אופס..",
        eventLimitExceededSubtitleLine1: "ניצלת את כל מכסת התמונות",
        eventLimitExceededSubtitleLine2: "לאירוע, נפגש באירוע הבא!",
      },
    },
  },
});
export default i18n;
