import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { MainScreen, MemoAd } from "../../UiElements";
import { EventActions } from "../../../actions";
import "./eventWaitingPage.scss";
import waitingIcon from "./images/event-waiting-icon.png";

class EventWaitingPage extends Component {
  componentWillMount() {
    EventActions.pageTitle(this.props.t("eventWaiting"));
  }

  render() {
    const { t } = this.props;

    return (
      <MainScreen id="event-waiting" footerElement={<MemoAd />}>
        <div className="waiting-wrapper">
          <img className="waiting-image" src={waitingIcon} alt="waiting" />
          <h1 className="waiting-title">{t("pageWaitingTitle")}</h1>
          <h2 className="waiting-subtitle-line-1">
            {t("pageWaitingSubtitleLine1")}
          </h2>
          <h2 className="waiting-subtitle-line-2">
            {t("pageWaitingSubtitleLine2")}
          </h2>
        </div>
      </MainScreen>
    );
  }
}

const EventWaitingPageWithT = withTranslation()(EventWaitingPage);
export { EventWaitingPageWithT as EventWaitingPage };
