import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { MainScreen } from "../UiElements";
import "./welcome.scss";
import memoLogo from "../UiElements/MemoAd/images/memoLogo.png";
import cameraBtn from "./images/cameraBtn.png";

class Welcome extends Component {
  constructor(props) {
    super(props);

    this.renderClientLogo = this.renderClientLogo.bind(this);
  }

  renderClientLogo() {
    const { logo } = this.props.event;
    if (!logo) return null;
    return (
      <div
        className="client-logo"
        style={{ backgroundImage: `url(${logo})` }}
      />
    );
  }

  render() {
    const { title, subtitle } = this.props.event;

    return (
      <MainScreen footerElement={<img width={60} src={memoLogo} alt="Memo" />}>
        <div className="open-camera-wrapper">
          <h1 className="event-title">{title}</h1>
          <h2 className="event-subtitle">{subtitle}</h2>
          <Link
            to={`${this.props.match.url.replace(/\/$/g, "")}/cam`}
            className="open-camera"
          >
            <img
              className="camera-img"
              width={120}
              src={cameraBtn}
              alt="Camera"
            />
          </Link>
        </div>
      </MainScreen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.eventReducer,
  };
};

const WelcomeWithRedux = connect(mapStateToProps)(Welcome);

export { WelcomeWithRedux as Welcome };
