import React, { Component } from "react";
import "./pic.scss";
import picIcon from "./images/picIcon.png";

class Pic extends Component {
  render() {
    return (
      <div id="camera-pic-btn" onClick={this.props.onClick}>
        <img src={picIcon} alt="Pic" />
      </div>
    );
  }
}

export { Pic };
