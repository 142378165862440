import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { PopupScreen } from "../UiElements";
import { uploadConstants } from "../../constants";
import errorIcon from "./images/error-icon.png";

import "./error.scss";

class ErrorPopup extends Component {
  onClose() {
    this.props.dispatch({ type: uploadConstants.RESET_UPLOAD });
  }

  render() {
    const { t, upload } = this.props;

    if (!upload || !upload.uploadError) return null;

    return (
      <div id="error-popup">
        <PopupScreen showCloseButton={true} onClose={this.onClose.bind(this)}>
          <div className="error-wrapper">
            <img className="error-icon" src={errorIcon} alt="Error" />
            <h1 className="error-title">{t("errorBigTitle")}</h1>
            <h2 className="error-subtitle">{t("errorSubTitle")}</h2>
          </div>
        </PopupScreen>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.eventReducer,
    upload: state.uploadReducer,
  };
};

const ErrorWithRedux = connect(mapStateToProps)(ErrorPopup);
const ErrorWithT = withTranslation()(ErrorWithRedux);

export { ErrorWithT as ErrorPopup };
