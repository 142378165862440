import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { PopupScreen } from "../UiElements";
import { CameraActions, EventActions } from "../../actions";
import onprogressIcon from "./images/onprogress-icon.png";
import onsuccessIcon from "./images/onsuccess-icon.png";
import { uploadConstants } from "../../constants";
import { redirect } from "../../utils";

import "./upload.scss";

class Upload extends Component {
  onClose() {
    this.props.dispatch({ type: uploadConstants.RESET_UPLOAD });
    this.props.dispatch(CameraActions.resetCopies());
  }

  nextImage() {
    this.props.onClosePreview();
    this.onClose();
    this.props.dispatch(CameraActions.restart());
  }

  redirectToEventLimitExceeded() {
    const url = EventActions.getEventShortenUrl();

    if (url) {
      redirect(`/${url}/event-limit-exceeded`);
    }
  }

  render() {
    const { t, upload } = this.props;
    const { uploadInProcess, successUpload, uploadError } = upload;

    if (!uploadError && !successUpload && !uploadInProcess) return null;
    let imagesLeft;

    if (
      upload &&
      upload.uploadResponse &&
      upload.uploadResponse.meta &&
      upload.uploadResponse.meta.images &&
      upload.uploadResponse.meta.images.maxUserImages &&
      upload.uploadResponse.meta.images.sessionUserImages
    ) {
      imagesLeft =
        upload.uploadResponse.meta.images.maxUserImages -
        upload.uploadResponse.meta.images.sessionUserImages;
    }

    const getShowCloseButton = () => {
      if (imagesLeft === 0) {
        return false;
      }
      if (uploadError) {
        return true;
      }
      if (successUpload) {
        return true;
      }
      return true;
    };

    return (
      <div id="upload-popup">
        <PopupScreen
          showCloseButton={getShowCloseButton()}
          onClose={this.onClose.bind(this)}
        >
          {uploadInProcess && (
            <div className="upload-progress-wrapper">
              <div className="upload-progress">
                <img
                  className="onprogress-icon"
                  src={onprogressIcon}
                  alt="On Progress"
                />
              </div>
              <h1 className="onprogress-title">
                {t("sendingForPrintingTitle")}
              </h1>
              <h2 className="onprogress-subtitle">
                {t("sendingForPrintingSubtitle")}
              </h2>
            </div>
          )}
          {successUpload && (
            <div className="upload-success-wrapper">
              <img
                className="onsuccess-icon"
                src={onsuccessIcon}
                alt="Success"
              />
              <div className="onsuccess-description">
                <h1 className="title">{t("onSuccessUpload")}</h1>
                <h2 className="on-success-upload-second-line">
                  {t("onSuccessUploadSecondLine")}
                </h2>
              </div>
              <div className="onsuccess-image-counter">
                <div className="image-counter-text show">
                  {t("imageCounter", {
                    number: imagesLeft,
                  })}
                </div>
              </div>
              <button
                className="on-success-btn"
                onClick={
                  imagesLeft === 0
                    ? this.redirectToEventLimitExceeded.bind(this)
                    : this.nextImage.bind(this)
                }
              >
                {t(
                  imagesLeft === 0
                    ? "onSuccessButtonLimitZero"
                    : "onSuccessButton"
                )}
              </button>
            </div>
          )}
        </PopupScreen>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.eventReducer,
    upload: state.uploadReducer,
  };
};

const UploadWithRedux = connect(mapStateToProps)(Upload);
const UploadWithT = withTranslation()(UploadWithRedux);

export { UploadWithT as Upload };
