import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { MainScreen, MemoAd } from "../../UiElements";
import "./eventClosedPage.scss";

import finishedIcon from "../finished/images/event-finished-icon.png";

class EventClosedPage extends Component {
  render() {
    const { t } = this.props;

    return (
      <MainScreen id="event-closed" footerElement={<MemoAd />}>
        <div className="finished-wrapper">
          <img className="finished-image" src={finishedIcon} alt="Finished" />
          <h1 className="finished-title">{t("eventFinished")}</h1>
          <h2 className="finished-subtitle">{t("eventFinishedSubtitle")}</h2>
        </div>
      </MainScreen>
    );
  }
}

const EventClosedPageWithT = withTranslation()(EventClosedPage);
export { EventClosedPageWithT as EventClosedPage };
