import React, { Component } from "react";
import { StickerActions } from "../../../../actions";
import { StickerItem } from "./";
import close from "./images/close.png";
import "./stickerList.scss";

class StickerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stickersUrl: [],
      stickers: [],
      defaultStickers: {
        url: "/images/stickers/",
        ex: "svg",
        start: 1,
        end: 17,
        prefix: "",
      },
    };
  }

  componentDidMount() {
    this.setState({
      stickersUrl: StickerActions.getStickersUrls(this.state.defaultStickers),
    });
  }

  renderStickerList() {
    return this.state.stickersUrl.map((url, i) => (
      <StickerItem onClick={this.props.onStickerClick} src={url} key={i} />
    ));
  }

  render() {
    if (!this.props.show) return null;

    return (
      <div id="camera-sticker-list">
        <button className="close-btn" onClick={this.props.close}>
          <img src={close} alt="close" />
        </button>
        <div className="stickers-wrapper">{this.renderStickerList()}</div>
      </div>
    );
  }
}

export { StickerList };
