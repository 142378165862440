import React from "react";
import { withTranslation } from "react-i18next";
import "./mainScreen.scss";

const MainScreen = ({ t, id, children, footerElement }) => (
  <div className="main-screen" id={id}>
    <div className="main-screen-wrapper">
      <div className="top-left" />
      <div className="main-screen-body">{children}</div>
      <div className="main-screen-footer">{footerElement}</div>
    </div>
  </div>
);
const mainScreenWithT = withTranslation()(MainScreen);

export { mainScreenWithT as MainScreen };
