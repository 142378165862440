import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { CameraActions, EventActions } from "../../actions";
import {
  Upload as UploadPopup,
  ErrorPopup,
  BlockedPopup,
  HorizontalScreen,
} from "./";
import {
  Pic,
  Flip,
  Upload,
  Preview,
  Trash,
  StickerList,
  Sticker,
} from "./buttons";
import backArrow from "./images/backArrow.png";
import "./camera.scss";

class Camera extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showStickers: false,
      showLimitPopup: true,
      showErrorPopup: true,
      isPreviewMode: false,
    };

    this.canvas = React.createRef();

    this.onUploadFromDevice = this.onUploadFromDevice.bind(this);
    this.toggleStickerList = this.toggleStickerList.bind(this);
    this.onPic = this.onPic.bind(this);
    this.onFlip = this.onFlip.bind(this);
    this.onTrash = this.onTrash.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClosePreview = this.onClosePreview.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onClose = this.onClose.bind(this);
    this.closeLimitPopup = this.closeLimitPopup.bind(this);
  }

  componentDidMount() {
    this.initCamera();
  }

  componentDidUpdate() {
    if (!CameraActions.isInit()) this.initCamera();
  }

  componentWillUnmount() {
    CameraActions.stop();
  }

  initCamera() {
    const { event, match } = this.props;
    this.props.dispatch(
      CameraActions.init(event, this.canvas.current, match.params.shortenUrl)
    );
  }

  onUploadFromDevice() {
    this.closeStickers();
    this.props.dispatch(CameraActions.upload());
  }

  onPic() {
    this.closeStickers();
    this.props.dispatch(CameraActions.pic());
  }

  onFlip() {
    this.closeStickers();
    this.props.dispatch(CameraActions.flip());
  }

  onTrash() {
    this.closeStickers();
    this.props.dispatch(CameraActions.restart());
  }

  onPreview() {
    this.setState({ isPreviewMode: true });
  }

  onClosePreview() {
    this.setState({ isPreviewMode: false });
  }

  onSubmit() {
    const { dispatch, event } = this.props;

    this.closeStickers();
    dispatch(EventActions.getEventData()); // validate user can send image (maybe event alraedy closed);

    if (event.closed) return;
    if (event.maxImages && event.maxImages.user <= 0) {
      return this.setState({ showLimitPopup: true });
    }

    const blob = CameraActions.getBlob();

    dispatch(
      EventActions.postImage({
        copies: 1,
        image: blob,
      })
    );
  }

  onClose() {
    this.closeStickers();
  }

  closeStickers() {
    this.setState({ showStickers: false });
  }

  toggleStickerList() {
    this.setState({
      showStickers: !this.state.showStickers,
    });
  }

  closeLimitPopup() {
    this.setState({ showLimitPopup: false });
  }

  render() {
    const { level } = this.props.camera;
    const { event, t } = this.props;
    const { showLimitPopup, showStickers, isPreviewMode } = this.state;

    return (
      <div id="camera" className={isPreviewMode ? "preview-mode" : ""}>
        {isPreviewMode && (
          <button className="back-button" onClick={this.onClosePreview}>
            <img className="back-arrow" src={backArrow} alt="Success" />
          </button>
        )}
        <div id="camera-wrapper">
          <canvas ref={this.canvas} />
        </div>
        {isPreviewMode ? (
          <div className="preview-actions-wrapper">
            <h1 className="preview-title">{t("previewTitle")}</h1>
            <h2 className="preview-subtitle">{t("previewSubtitle")}</h2>
            <button className="submit-button" onClick={this.onSubmit}>
              {t("sendToPrint")}
            </button>
            <button className="return-button" onClick={this.onClosePreview}>
              {t("return")}
            </button>
          </div>
        ) : (
          <div className="camera-bottom-button">
            <div
              className={`buttons buttons-before-pic ${
                level === 1 ? "active" : ""
              }`}
            >
              <div className="buttons-group buttons-group-right">
                <Flip onClick={this.onFlip} />
              </div>
              <Pic onClick={this.onPic} />
              <div className="buttons-group buttons-group-left">
                <Upload onClick={this.onUploadFromDevice} />
              </div>
            </div>
            <div
              className={`buttons buttons-after-pic ${
                level === 2 ? "active" : ""
              }`}
            >
              <div className="buttons-group buttons-group-right">
                <Sticker onClick={this.toggleStickerList} />
              </div>
              <Preview onClick={this.onPreview} />
              <div className="buttons-group buttons-group-left">
                <Trash onClick={this.onTrash} />
              </div>
            </div>
            <StickerList
              close={this.onClose}
              onStickerClick={this.toggleStickerList}
              show={showStickers}
            />
          </div>
        )}
        <UploadPopup onClosePreview={this.onClosePreview} />
        <BlockedPopup />
        <ErrorPopup />
        <HorizontalScreen />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.eventReducer,
    camera: state.cameraReducer,
  };
};

const CameraWithRedux = connect(mapStateToProps)(Camera);
const CameraWithT = withTranslation()(CameraWithRedux);

export { CameraWithT as Camera };
