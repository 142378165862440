import React from "react";
import { withTranslation } from "react-i18next";
import "./memoAd.scss";
import memoLogo from "./images/memoLogo.png";
import { eventConstants } from "../../../constants";

const MemoAd = ({ t }) => (
  <div className="memo-ad-wrapper">
    <img width={60} src={memoLogo} alt="Memo" />
    <h3 className="want-memo-at-your-event">{t("wantMemoAtYourEvent")}</h3>
    <a
      className="click-for-more-details"
      target="_blank"
      rel="noopener noreferrer"
      href={eventConstants.WHATS_APP_CHAT_URL}
    >
      {t("clickForMoreDetails")}
    </a>
  </div>
);
const memoAdWithT = withTranslation()(MemoAd);

export { memoAdWithT as MemoAd };
